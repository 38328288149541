import { gql } from "@apollo/client";

export const INSURANCE_REQUIREMENT_QUERY = gql`
  query InsuranceRequirement {
    insuranceRequirements {
      created
      publicId
      name
      jobCategories {
        edges {
          node {
            className
          }
        }
      }
      coverageRules {
        edges {
          node {
            id
            ruleName
            ruleJson
          }
        }
      }
    }
  }
`

export const INSURANCE_REQUIREMENT_BY_ID_QUERY = gql`
  query InsuranceRequirementByID($id: ID!) {
    insuranceRequirement(id: $id) {
      publicId
      name
      jobCategories {
        edges {
          node {
            className
            code
          }
        }
      }
      coverageRules {
        edges {
          node {
            id
            ruleName
            ruleJson
          }
        }
      }
    }
  }
`

export const INSURANCE_REQUIREMENT_AVAILABLE_JOB_CATEGORIES = gql`
  query InsuranceRequirementAvailableJobCategories {
    insuranceRequirementAvailableJobCategories {
        className
        classId
        code
    }
  }
`