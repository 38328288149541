import React, { useState } from "react";

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import MainWrapper from "../../components/MainWrapper";

import CustomBadge from "./CustomBadge";

import CertificateDetailForm from "./CertificateDetailForm";

import { UPDATE_CERTIFICATE } from "./mutations";
import { CERTIFICATE_AUDIT_QUERY } from "./queries";

function CertificateReviewDetail(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const cursor = searchParams.get('starting_after')

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [updateCertificate, { loading, error, reset }] = useMutation(
    UPDATE_CERTIFICATE,
    {
      onCompleted: () => {
        navigate("/certificates");
      },
      refetchQueries: [
        {
          query: CERTIFICATE_AUDIT_QUERY,
          variables: { cursor }, // Specify the variables for the query
        }
      ],
    }
  );

  const { 
    insuranceRequirement,
    parsedCertificateJson, 
    auditResults, 
    certificate,
    publicId, 
    status 
  } = state?.node ?? {};
  
  const auditData = JSON.parse(parsedCertificateJson ?? '{"error": "No JSON found: Extracted/Audit Data"}');
  if (auditData.error) {
    console.error(auditData.error);
  }

  const [expandedPDF, setExpandedPDF] = useState(false);

  const expandIcon = (<img src="/expand.svg" alt="Expand" className="v-mid"/>);
  const minimizeIcon = (<img src="/minimize.svg" alt="Minimize" className="v-mid" />);

  const pdfButton = expandedPDF ? minimizeIcon : expandIcon;

  function renderProcessingPlaceholder(){
    return (
      <div className="flex items-center justify-center h-100 w-100">
        <img src="/processing.gif" alt="Processing..." />
      </div>
    )
  }
  
  function renderCertificateDetail(){
    return (
      <>
        <div className={expandedPDF ? "dn" : "w-50"}>
          <CertificateDetailForm
            auditData={auditData}
            auditResult={auditResults}
            status={status}
            publicId={publicId}
            updateCertificate={updateCertificate}
          />
        </div>
        <div className={expandedPDF ? "w-100 vh-100" : "w-50 vh-100"}>
          <iframe style={{ width: "100%", height: "100%" }} src={certificate?.pdfUrl} />
        </div>
      </>
    )
  }

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <MainWrapper isTestView={props.isTestView}>
      <div className="flex-item content-center absolute top-1 left-1">
        <div className="back-arrow" onClick={handleBackClick}>
          <span className="f6 hover-bg-light-gray pointer pv2 ph3 br4">← Back</span>
        </div>
      </div>

      <div className="mt4">
        <div className="mt0 mb4">
          <h2 className="fw3 dark-gray flex items-center justify-between mb0">
            <div className="flex items-center">
              <div className="mr2">{certificate?.filename}</div><CustomBadge status={status} />
            </div>
            <button
              className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box"
              onClick={() => setExpandedPDF((value) => !value)}
            >
              {pdfButton}
            </button>
          </h2>
          <div className="f7 pv2 black-50">
            Reviewed against the <Link className="blue" to={`/organization/requirements/${insuranceRequirement?.publicId}`}>{insuranceRequirement?.name}</Link> set of insurance requirements.
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        {status == "PROCESSING" ? renderProcessingPlaceholder() : renderCertificateDetail()}
      </div>
    </MainWrapper>
  );
}

export default CertificateReviewDetail;
