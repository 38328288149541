//@ts-nocheck
import { __rest } from "tslib";

import React, { useContext } from 'react'

import { Route, Routes, NavLink, Navigate, useLocation } from "react-router-dom";
import {
  from,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { environments } from "../constants/environments";
import { gql, useQuery, useLazyQuery } from "@apollo/client";

import promiseToObservable from "../utils/promiseToObservable";
import * as cognito from "../libs/cognito";

import DashboardWrapper from "./DashboardWrapper";

import OrganizationUsers from "../pages/OrganizationPage/OrganizationUsers";
import {Settings as OrganizationSettings } from "../pages/OrganizationPage/SettingsPage";
import {Invoices as OrganizationInvoices} from "../pages/BillingPage/Invoices";

import ReviewJobsAdmin from "../pages/ReviewJobsAdmin";
import QuestionsAdminForm from "../pages/QuestionsAdminForm";
import AuditRatesAdmin from "../pages/AuditRatesAdmin";

import PaymentAdmin from "../pages/PaymentAdminPage/PaymentAdmin";
import ReportingAdmin from "../pages/ReportingAdmin";
import FilingAdmin from "../pages/FilingAdmin";

import KeysPage from "../pages/KeysPage";
import WebhooksPage from "../pages/WebhooksPage";
import OverviewPage from "../pages/OverviewPage/Overview";
import JobCodesPage from "../pages/JobPage/JobCodes";
import Contractors from "../pages/ContractorPage/Contractors";
import CertificateReview from "../pages/CertificateReviewPage/CertificateReview";
import Trackers from "../pages/TrackerPage/Trackers";

import SignIn from "../pages/auth/SignIn";
import OTP from "../pages/auth/OTP";
import SignUp from "../pages/auth/SignUp";
import VerifyCode from "../pages/auth/Verify";
import RequestCode from "../pages/auth/RequestCode";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ChangePassword from "../pages/auth/ChangePassword";

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from '../contexts/authContext'

import { AuthContext } from '../contexts/authContext'
import { gqlErrors } from "../constants/errors";

import { USER_QUERY } from "./queries";

const routes = [];

routes.push({
  path: "/overview",
  section: "general",
  sidebar: () => (
    <NavLink
      to="/overview"
      end
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Overview
    </NavLink>
  ),
  main: (routeParams) => <OverviewPage user={routeParams.user} isTestView={routeParams.isTestView} organization={routeParams.organization} />,
});

routes.push({
  path: "/keys",
  section: "developers",
  sidebar: () => (
    <NavLink
      to="/keys"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      API Keys
    </NavLink>
  ),
  main: (routeParams) => <KeysPage user={routeParams.user} isTestView={routeParams.isTestView} organization={routeParams.organization} />,
});

routes.push({
  path: "/webhooks",
  section: "developers",
  sidebar: () => (
    <NavLink
      to="/webhooks"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Webhooks
    </NavLink>
  ),
  main: (routeParams) => <WebhooksPage user={routeParams.user} client={routeParams.client} isTestView={routeParams.isTestView}/>,
});

routes.push({
  path: "/contractors/*",
  section: "general",
  sidebar: () => (
    <NavLink
      to="/contractors"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Contractors
    </NavLink>
  ),
  main: (routeParams) => (
    <Contractors
      user={routeParams.user}
      token={routeParams.token}
      client={routeParams.client}
      isTestView={routeParams.isTestView}
      organization={routeParams.organization}
      isAdmin={routeParams.isAdmin}
    />
  ),
});

routes.push({
  path: "/certificates/*",
  section: "general",
  sidebar: (routeParams) => {
    const allowedReviewCoiRoles = ["admin"];

    const currentUserRoles = routeParams.user.roles.edges;
    const currentOrganizationRole = routeParams.user?.organizations?.edges[0]?.node?.organizationRole?.role;
    const roles = [...currentUserRoles, {node: currentOrganizationRole}]
    
    const isReviewCoiVisible = roles.some(role => allowedReviewCoiRoles.includes(role.node.name));

    if (!isReviewCoiVisible) {
      return null
    }

    return (
      <NavLink
        to="/certificates"
        className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
      >
        Certificates
      </NavLink>
    )
  },
  main: (routeParams) => (
    <CertificateReview 
      user={routeParams.user} 
      isTestView={routeParams.isTestView}
      token={routeParams.token}
      orgId={routeParams.orgId}
    />),
});

routes.push({
  path: "/trackers",
  section: "general",
  sidebar: (routeParams) => {
    const allowedTrackerRoles = ["admin"];

    const currentUserRoles = routeParams.user.roles.edges;
    const currentOrganizationRole = routeParams.user?.organizations?.edges[0]?.node?.organizationRole?.role;
    const roles = [...currentUserRoles, {node: currentOrganizationRole}]
    
    const isTrackerVisible = roles.some(role => allowedTrackerRoles.includes(role.node.name));

    if (!isTrackerVisible) {
      return null
    }

    return (
    <NavLink
      to="/trackers"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Trackers
    </NavLink>
  )},
  main: (routeParams) => (
    <Trackers
      client={routeParams.client} 
      user={routeParams.user} 
      isTestView={routeParams.isTestView} 
      token={routeParams.token} 
    />
  ),
});

routes.push({
  path: "/job-codes",
  section: "general",
  sidebar: () => (
    <NavLink
      to="/job-codes"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Job Codes
    </NavLink>
  ),
  main: (routeParams) => <JobCodesPage user={routeParams.user} isTestView={routeParams.isTestView} />,
});


routes.push({
  path: "/organization/billing",
  section: "organization",
  sidebar: (routeParams) => {
    const allowedBillingRoles = ["admin"];
    
    const currentUserRoles = routeParams.user.roles.edges;
    const currentOrganizationRole = routeParams.user?.organizations?.edges[0]?.node?.organizationRole?.role;
    const roles = [...currentUserRoles, {node: currentOrganizationRole}]
    
    const isBillingVisible = roles.some(role => allowedBillingRoles.includes(role.node.name));

    if (!isBillingVisible) {
      return null
    }

    return (
        <NavLink
          to="/organization/billing"
          className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
        >
          Billing
        </NavLink>
      )
  },
  main: (routeParams) => (
    <OrganizationInvoices 
      user={routeParams.user} 
      client={routeParams.client} 
      isTestView={routeParams.isTestView} 
      organization={routeParams.organization}/>
  )
});


routes.push({
  path: "/organization/*",
  section: "organization",
  sidebar: () => {
    const location = useLocation(); // Step 2: Use useLocation to get the current location

    // Step 3: Function to check if the link should be active
    const isActive = (path: string) => {
      return ["/organization/settings", "/organization/requirements"].some(basePath => path.startsWith(basePath));
    };

    return (
      <NavLink
        to="/organization/settings"
        className={() => `link dim mr4 f6 near-black outline-0 bn ${isActive(location.pathname) ? 'brand-pink' : ''}`}
      >
        Settings
      </NavLink>)
  },
  main: (routeParams) => (
    <OrganizationSettings 
      user={routeParams.user} 
      client={routeParams.client} 
      isTestView={routeParams.isTestView} 
      organization={routeParams.organization}
      isAdmin={routeParams.isAdmin}
    />
    ),
});

routes.push({
  path: "/organization/users",
  section: "organization",
  sidebar: () => (
    <NavLink
      to="/organization/users"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Team
    </NavLink>
  ),
  main: (routeParams) => <OrganizationUsers user={routeParams.user} client={routeParams.client} isTestView={routeParams.isTestView} organization={routeParams.organization} isAdmin={routeParams.isAdmin}/>,
});

routes.push({
  path: "/admin/review",
  section: "admin",
  sidebar: () => (
    <NavLink
      to="/admin/review"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Review Jobs
    </NavLink>
  ),
  main: (routeParams) => <ReviewJobsAdmin user={routeParams.user} token={routeParams.token} isTestView={routeParams.isTestView} isAdmin={routeParams.isAdmin} />,
});

routes.push({
  path: "/admin/rates",
  section: "admin",
  sidebar: () => (
    <NavLink
      to="/admin/rates"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Audit Rates
    </NavLink>
  ),
  main: (routeParams) => <AuditRatesAdmin user={routeParams.user} token={routeParams.token} isTestView={routeParams.isTestView} isAdmin={routeParams.isAdmin} />,
});

routes.push({
  path: "/admin/questions",
  section: "admin",
  sidebar: () => (
    <NavLink
      to="/admin/questions"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Search Questions
    </NavLink>
  ),
  main: (routeParams) => <QuestionsAdminForm user={routeParams.user} token={routeParams.token} isTestView={routeParams.isTestView} isAdmin={routeParams.isAdmin} />,
});

routes.push({
  path: "/admin/payment",
  section: "admin",
  sidebar: () => (
    <NavLink
      to="/admin/payment"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Payments
    </NavLink>
  ),
  main: (routeParams) => <PaymentAdmin user={routeParams.user} token={routeParams.token} isTestView={routeParams.isTestView} isAdmin={routeParams.isAdmin} />,
})

routes.push({
  path: "/admin/reporting",
  section: "admin",
  sidebar: () => (
    <NavLink
      to="/admin/reporting"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Reporting
    </NavLink>
  ),
  main: (routeParams) => <ReportingAdmin user={routeParams.user} token={routeParams.token} isTestView={routeParams.isTestView} isAdmin={routeParams.isAdmin} />,
})

routes.push({
  path: "/admin/filings",
  section: "admin",
  sidebar: () => (
    <NavLink
      to="/admin/filings"
      className={({ isActive }) => `link dim mr4 f6 near-black outline-0 bn ${isActive ? 'brand-pink' : ''}`}
    >
      Filings
    </NavLink>
  ),
  main: (routeParams) => <FilingAdmin token={routeParams.token} isTestView={routeParams.isTestView} isAdmin={routeParams.isAdmin} />,
})

const LoggedInPage = ({client, loading, error, data}) => {
  const auth = useContext(AuthContext)
  return (
    <DashboardWrapper
      token={auth.sessionInfo?.idToken}
      routes={routes}
      client={client}
      data={data}
      loading={loading}
      error={error}
    />
  );
};

const SignInRoute: React.FunctionComponent = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/signin" />} />
    <Route path="/*" element={<Navigate replace to="/signin" />} />
    <Route path="/signin" element={<SignIn />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/complete-registration" element={<VerifyCode />} />
    <Route path="/reset" element={<RequestCode />} />
    <Route path="/reset-password" element={<ForgotPassword />} />
  </Routes>
);

const MainRoute: React.FunctionComponent = () => {
  const getTargetEnv = () => {
    const target_env =
      localStorage.getItem("Ten99PolicyEnvironment") || environments.PRODUCTION;

    return target_env;
  };

  const customFetch = (uri, options) => {
    const target_env = getTargetEnv();
    const custom_uri =
      target_env === environments.SANDBOX
        ? process.env.REACT_APP_TEN99_SANDBOX_URL
        : process.env.REACT_APP_TEN99_URL;
    return fetch(`${custom_uri}`, options);
  };

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_TEN99_BASE_URL,
    fetch: customFetch,
    headers: {
      "Ten99Policy-Environment": getTargetEnv(),
    },
  });

  const auth = useContext(AuthContext)
  // console.log(auth?.sessionInfo);

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = auth.sessionInfo?.idToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
        "Ten99Policy-Organization":
          localStorage.getItem("Ten99PolicyOrganization") || "",
        "Ten99Policy-Environment":
          localStorage.getItem("Ten99PolicyEnvironment") ||
          environments.PRODUCTION,
      },
    };
  });

  const errorLink = onError(
    ({ networkError, graphQLErrors, operation, forward }) => {
      // raw "response" set by apollo-link-http
      const { response } = operation.getContext();
  
      if (response?.status === 401) {
        return promiseToObservable(cognito.refreshToken()).flatMap(() =>
          forward(operation)
        );
      }
      if (response?.status === 403) {
        return promiseToObservable(cognito.refreshToken()).flatMap(() =>
          forward(operation)
        );
      }
  
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          if (extensions?.status === gqlErrors.REFRESH_TOKEN) {
            console.log("refresh_token");
            return promiseToObservable(cognito.refreshToken()).flatMap(() =>
              forward(operation)
            );
          }
          if (extensions?.status === gqlErrors.UNAUTHENTICATED) {
            // navigate("/signin");
            console.log("unauthenticated");
          }

          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
    }
  );
  
  const cache = new InMemoryCache({
    possibleTypes: {
      WebhookEndpoints: ["WebhookEndpoint"],
    },
  });
  
  const client = new ApolloClient({
    connectToDevTools: true,
    link: from([authLink, errorLink, httpLink]),
    cache: cache,
  });

  return (
    <ApolloProvider client={client}>
      <Container client={client}></Container>
    </ApolloProvider>
  );
};

const Container = ({ client }) => {
  const { loading, error, data } = useQuery(USER_QUERY);

  if (
      data &&
      data?.currentUser?.twoFaEnabled &&
      data?.currentUser?.needsOtpCheck
    ) {
    return (
      <OTP />
    )
  }

  return (
    <Routes>
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/" element={<Navigate replace to="/overview" />} />
      <Route path="/signup" element={<Navigate replace to="/overview" />} />
      <Route path="/signin" element={<Navigate replace to="/overview" />} />
      <Route path="/*" element={<LoggedInPage client={client} loading={loading} error={error} data={data} />} />
    </Routes>
  )
}

const Dashboard = ({ state, user, attributes }) => {
  return (
    <AuthProvider>
      <AuthIsSignedIn>
        <MainRoute />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  );
};

export default Dashboard;
