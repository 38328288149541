import React, {useEffect, useState, useMemo} from 'react';

import ReactLoading from 'react-loading';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip'
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { gql, useQuery, useMutation } from "@apollo/client";

import moment from 'moment-timezone';
import classNames from 'classnames'
import dateFnsFormat from 'date-fns/format';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { shortCoverageNames, getPolicies, resolveRedacted } from './helpers';

import PageHeader from '../../components/PageHeader';
import MainWrapper from '../../components/MainWrapper';
import AutoSizingInputAdvanced from '../../components/AutoSizingInputAdvanced';

import { createValidationSchema, getStyles} from './formValidation';

import { startAndEnd } from '../../utils/truncate'
import {centsToDollars} from '../../utils/currency'
import { formatDate, parseDate } from '../../utils/date'

import { states } from '../../constants/states';

import {
  CONTRACTOR_BY_ID_QUERY,
  ASSIGNMENT_INVOICE_HISTORY,
  JOB_CATEGORY_QUERY
} from './queries';

import { 
  CANCEL_CONTRACTOR_POLICY,
  CREATE_QUOTE, 
  CREATE_ASSSIGNMENT,
  UPDATE_CONTRACTOR_DETAILS,
  EXPIRE_RENEW_APPLICATION,
  MARK_OFAC_USER_EXCEPTION,
  CANCEL_ASSIGNMENT,
  UPDATE_ASSIGNMENT_WAGE,
} from './mutations'

import CurrencyInput from '../../components/CurrencyInput';

Modal.setAppElement('#root');

const FORMAT = 'MM/dd/yyyy';

const today = new Date()
let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

let oneYear = new Date()
oneYear.setDate(oneYear.getDate() + 365) 

let contentStyle = {
  width                 : '40%',
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
}

let modalStyles = {
  content : contentStyle,
  overlay : {
    backgroundColor : 'rgba(0, 0, 0, 0.5)'
  } 
};

function buildJobHistory(quotes, assignments){
  // Filter out quotes where voided is true
  let filteredQuotes = quotes.filter(quote => !quote?.node?.voided);
  let quotes_and_assignments = [...filteredQuotes];

  function dateDescFn(a,b){
    if (a?.node?.effectiveDate < b?.node?.effectiveDate){
      return 1
    }
    if (a?.node?.effectiveDate > b?.node?.effectiveDate){
      return -1
    }
    return 0
  }
  if (assignments.length > 0){
    let filteredAssignments = assignments.filter(assignment => !assignment?.node?.voided);
    quotes_and_assignments = [...filteredQuotes, ...filteredAssignments];
  }
  quotes_and_assignments.sort(dateDescFn)
  return quotes_and_assignments
}

function EditButton(props){
  const {
    resetForm,
    errors,
    isLoading,
    isSubmitting,
    setIsActiveEdit,
  } = props
  
  const loadingSpinner = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} width={14} height={14} />
  )

  const renderEdit = () => {
    return (
      <div className='absolute top-2 right-2'>
      <button 
        id="editContractorDetails"
        type="button" 
        onClick={() => setIsActiveEdit(true)}
        className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box">
        Edit
      </button>
      </div>
    )    
  }

  const renderCancelSave = () => {
    const isDisabled = Object.keys(errors).length > 0
    return (
      <div className='absolute top-2 right-2'>
        <button 
        id="cancelEditContractorDetails"
        type="button" 
        onClick={() => {
          setIsActiveEdit(false)
          resetForm()
        }}
        className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box">
          Cancel
        </button>
        <button 
        id="saveedit"
        type="submit" 
        disabled={isDisabled || isLoading || isSubmitting}
        className={classNames(
          "fr outline-0 pointer br2 ba b--black-20  pa2 ml1 f7 lh-title border-box",
          { 'bg-blue white': !isDisabled },
          { 'bg-black-10 black': isDisabled })
          }>
          {isLoading ? loadingSpinner() : 'Save'}
        </button>
      </div>
    )
  }

  return (
    props.isActiveEdit ? renderCancelSave() : renderEdit()
  )
}

function CancelPolicyModal(props){
  const [cancelContractorPolicy, { loading, error, reset }] = useMutation(CANCEL_CONTRACTOR_POLICY, {
    onCompleted: ()=>{
      props.closeModal();
      props.setConfirmedCancelledPolicies([...props.confirmedCancelledPolicies, props.policy])
      props.setSuccessMessage(`The policy with the id matching ${props.policy} was successfully cancelled.`)
      reset()
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3">There was an error cancelling the policy. Please try again.</span>
    </div>
  )

  const renderCancelConfirmation = (props) => (
    <div className="roboto">
      <PageHeader title={'Confirm Policy Cancellation'} />
      {error ? renderError() : null}
      <div className="f6 fw5 db mb2 mt3">
        Are you sure you want to cancel the policy with the following id <b className="red">{props.policy}</b>? 
        This operation can't be undone.
      </div>
      <div className="mv4">
        <button 
          className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-white b--light-red red ba pointer fr"
          type="submit"
          onClick={(e) => {
            const policy = props.policy
            cancelContractorPolicy({
              variables: {policy}
            })
          }}
          >
          YES – CANCEL the policy!
        </button>
        <button 
          onClick={props.closeModal}
          className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
          type="submit">
          Cancel
        </button>
      </div>
    </div>
  )

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Cancel insurance coverage."
    >
      {
        loading ? renderLoading() : renderCancelConfirmation(props)
      }
    </Modal>
  )
}
function CancelAssignmentModal(props){
  const [cancelAssignment, { loading, error, reset }] = useMutation(CANCEL_ASSIGNMENT, {
    onCompleted: ()=>{
      props.closeModal();
      props.setSuccessMessage(`The assignment with the id matching ${props.assignment} was successfully cancelled.`)
      reset()
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = (errorMsg) => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3"> { errorMsg ?? "There was an error cancelling the assignment. Please try again." }</span>
    </div>
  )

  const renderCancelConfirmation = (props) => (
    <div className="roboto">
      <PageHeader title={'Confirm Assignment Cancellation'} />
      {error ? renderError(error.message) : null}
      <div className="f6 fw5 db mb2 mt3">
        Are you sure you want to cancel the Assignment with the following id <b className="red">{props.assignment}</b>? 
        This operation can't be undone.
      </div>
      <div className="mv4">
        <button 
          className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-white b--light-red red ba pointer fr"
          type="submit"
          onClick={(e) => {
            const assignment = props.assignment
            cancelAssignment({
              variables: {assignment}
            })
          }}
          >
          YES – CANCEL the assignment!
        </button>
        <button 
          onClick={props.closeModal}
          className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
          type="submit">
          Cancel
        </button>
      </div>
    </div>
  )

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Cancel assignment coverage."
    >
      {
        loading ? renderLoading() : renderCancelConfirmation(props)
      }
    </Modal>
  )
}


const JobAssignmentForm = (props) => {
  const {
    errors,
    errorMessage,
    data,
    isSubmitting,
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    categoryCodeData
  } = props;
  
  const quotes = data?.quotes?.edges
  const quotes_with_active_policies = quotes.filter(obj => obj?.node?.policy?.edges[0]?.node?.isActive)
  
  const [selectedValue, setSelectedValue] = useState(quotes_with_active_policies[0].node.publicId);
  const [clientEntityAddress, setClientEntityAddress] = useState(
    false
  )
  
  const preapprovals = getPreapprovals(data?.quotes?.edges)

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mb3">
      <span className="lh-title ml3">There was an error creating the job assignment. Please try again.</span>
    </div>
  )

  function getPreapprovals(quotes){
    if (!quotes || quotes.length == 0){
      return []
    }

    const preapprovals = quotes
      .map(obj => {
        return {
          ['policyId']: obj?.node?.policy?.edges[0]?.node?.publicId,
          ['quoteId']: obj?.node.publicId,
          ['jobCategoryCode']: obj?.node.job?.jobCategory?.code,
          ['jobCategory']: obj?.node.job?.jobCategory?.className,
          ['coverageTypes']: shortCoverageNames(obj?.node?.coverageType?.edges),
          ['quoteJsonWorkState']: obj?.node?.quoteJsonWorkState,
          ['quoteJsonResidenceState']: obj?.node?.quoteJsonResidenceState,
        }
      })
      .filter(value => value.policyId !== null && value.policyId !== undefined)
      .filter(value => value !== null && value !== undefined);
      
      return preapprovals
  }
  
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    // Update Formik's field value
    setFieldValue(event.target.name, event.target.value);
  };

  const renderNewApprovalFields = () => {
    const jobCategoryOptions = (dropdownOptions) => {
      const filteredOptions = dropdownOptions.filter(option => option.className !== null)
      return (
        <Field 
          component="select"
          name="jobCategoryCode"
          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
        >
          {filteredOptions.map(option => (
            <option key={option.code} value={option.code}>
              {startAndEnd(option.className)}
            </option>
          ))}
        </Field>
      )
    }

    const coverageTypeOptions = () => {
      const dropdownOptions = [
        {label: 'WC', key:'workers-comp'},
        {label:'GL', key:'general'},
        {label: 'WC, GL', key:'workers-comp,general'}
      ]

      return (
        <Field 
          component="select"
          name="coverageTypes"
          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
        >
          {dropdownOptions.map(option => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </Field>
      )
    }

    const stateOptions = (dropdownOptions, locationType) => {
      return (
        <Field 
          component="select"
          name={locationType}
          className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
        >
          {dropdownOptions.map(option => (
            <option key={[option.abbreviation, locationType].join('-')} value={option.abbreviation}>
              {option.abbreviation}
            </option>
          ))}
        </Field>
      )
    }

    return(
      <tr className="bb" key='newFields'>
        <td className="tl ph2 pv3 f7"></td>
        <td className="tl ph2 pv3 f7">
          {jobCategoryOptions(categoryCodeData?.jobCategory)}
        </td>
        <td className='tl ph2 pv3 f7'>
          {coverageTypeOptions()}
        </td>
        <td className='tl ph2 pv3 f7'>
          {stateOptions(states, 'workState')}
        </td>
        <td className='tl ph2 pv3 f7'>
          {stateOptions(states, 'residenceState')}
        </td>
      </tr>
    )
  }

  const renderNewApprovalRow = () => {
    const NEW = 'new'
    return (
      <>
        <tr className="bb" key={NEW}>
          <td className="tl ph2 pv3 f7">
            <Field
              name="selectedPolicy"
              type="radio"
              value={NEW}
              checked={selectedValue === NEW} 
              onChange={handleRadioChange} 
            />
          </td>
          <td colSpan={4}>
            <div className="dt">
              <span className="dtc v-mid lh-copy f5 fw4">+</span> 
              <span className="dtc tl ph2 pv3 f7 fw4 lh-copy"> 
                Create a new job category approval <br/>
                <span className="gray">Note: Select if you need to change job category, work and/or home state.</span>
              </span>
            </div>
          </td>
        </tr>
        {selectedValue === NEW && renderNewApprovalFields()}
      </>
    )
  }

  const renderPreapprovalRows = (preApprovals) => {
    const rows = preApprovals.map((obj) => {
      return (
        <tr className="bb" key={obj?.quoteId}>
          <td className="tl ph2 pv3 f7">
          <Field
              name="selectedPolicy"
              type="radio"
              value={obj?.quoteId}
              checked={selectedValue === obj?.quoteId} 
              onChange={handleRadioChange} 
          />
          </td>
          <td className="tl ph2 pv3 f7">
            <Field type="text" className="dn" name="jobCategoryCode" value={obj?.jobCategoryCode} />
              {startAndEnd(obj?.jobCategory)}
          </td>
          <td className="tl ph2 pv3 f7">{obj?.coverageTypes}</td>
          <td className="tl ph2 pv3 f7">{obj?.quoteJsonWorkState ? obj?.quoteJsonWorkState : '-' }</td>
          <td className="tl ph2 pv3 f7">{obj?.quoteJsonResidenceState ? obj?.quoteJsonResidenceState : '-'}</td>
        </tr>
      )
    })
    return rows
  }

  const renderPreapprovalTable = (preApprovals) => {
    return (
      <div className="w-100">
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
          <thead>
            <tr className="bb b--light-gray">
              <th className="tl pv3 ph2 fw5 f7 ttu w-5"></th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-40">Job Category</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-20">Coverage type</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-15">Work state</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-15">Residence state</th>
            </tr>
          </thead>
          <tbody>
            {renderPreapprovalRows(preApprovals)}
            {renderNewApprovalRow()}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage ? renderError() : null}
      <div
        className="overflow-y-scroll bt bb b--black-20 pb4"
        style={{ height: "520px" }}
      >
        <div className="relative dib w-100 pv3 f6">
          <div className="mv3">
            <h3 className="dark-gray bb b--black-10 mt0 pb3">
              Select an Approved Job Category
            </h3>
            {renderPreapprovalTable(preapprovals)}
          </div>
          <div className="mt4">
            <h3 className="dark-gray bb b--black-10 mt0 pb3">
              Client Entity
            </h3>
            <div className="pb2">
              <span className="w-100">
                <label htmlFor="entityName" className="f6 fw5 db mb2 mt3">Client entity name</label>
                <Field name="entityName"
                  style={getStyles(errors, 'entityName', touched)}
                  className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                  type="text"
                  value={values.entityName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="e.g., Salesforce"
                />
              </span>
            </div>
            <div className="mv3">
              <label className="f6 pointer">
                <input type="checkbox" onClick={() => (setClientEntityAddress(!clientEntityAddress))}/> Add client entity address
              </label>
            </div>
            { clientEntityAddress && 
              <div className="w-100">
                <div className="pb2">
                  <span className="w-100">
                    <label htmlFor="entityAddressLine1" className="absolute o-0 f6 fw5 db mb2 mt3">Address line 1</label>
                    <Field name="entityAddressLine1" 
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                      value={values.entityAddressLine1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address line 1 (e.g., 123 Main St)"
                    />
                  </span>
                </div>

                <div className="pb2">
                  <span className="w-100">
                    <label htmlFor="entityAddressLine2" className="absolute o-0 f6 fw5 db mb2 mt3">Address line 2</label>
                    <Field name="entityAddressLine2" 
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                      value={values.entityAddressLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address line 2"
                    />
                  </span>
                </div>

                <div className="pb2">
                  <span className="w-100">
                    <label htmlFor="entityAddressLine3" className="absolute o-0 f6 fw5 db mb2 mt3">Address line 3</label>
                    <Field name="entityAddressLine3" 
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                      value={values.entityAddressLine3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Address line 3"
                    />
                  </span>
                </div>
                
                <div className="db">
                  <div className="pb2 dib w-50 mr2">
                    <span className="w-100">
                      <label htmlFor="entityAddressLocality" className="absolute o-0 f6 fw5 db mb2 mt3">City</label>
                      <Field name="entityAddressLocality" 
                        className="outline-0 pa2 f6 fw3 ba b--black-10 br2 w-100"
                        type="text"
                        value={values.entityAddressLocality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="City"
                      />
                    </span>
                  </div>

                  <div className="pb2 dib w-20 ml2">
                    <span className="w-100">
                      <label htmlFor="entityAddressRegion" className="absolute o-0 f6 fw5 db mb2 mt3">State</label>
                      <Field name="entityAddressRegion" 
                        className="outline-0 pa2 f6 fw3 ba b--black-10 br2 w-100"
                        type="text"
                        value={values.entityAddressRegion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="State"
                      />
                    </span>
                  </div>

                  <div className="pb2 dib w-25 ml2">
                    <span>
                      <label htmlFor="entityAddressPostalcode" className="absolute o-0 f6 fw5 db mb2 mt3">Zip Code</label>
                      <Field name="entityAddressPostalcode" 
                        className="outline-0 pa2 f6 fw3 ba b--black-10 br2 w-100"
                        type="text"
                        value={values.entityAddressPostalcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Zip code"
                      />
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="mt4">
            <h3 className="dark-gray bb b--black-10 mt0 pb3">
              Job Details
            </h3>

            <div className="pb2">
              <span className="w-100">
                <label htmlFor="jobName" className="f6 fw5 db mb2 mt3">Job name</label>
                <Field name="jobName"
                  style={getStyles(errors, 'jobName', touched)}
                  className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                  type="text"
                  placeholder="e.g., Instagram Influencer Post"
                  value={values.jobName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </span>
            </div>

            <div className="pb2">
              <span className="w-100">
                <label htmlFor="jobDescription" className="f6 fw5 db mb2 mt3">Description</label>
                <Field name="jobDescription"
                  style={getStyles(errors, 'jobDescription', touched)}
                  className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                  component="textarea"
                  rows="4" 
                  cols="40" 
                  placeholder="A 2-3 sentence description of the work that the contractor will be doing."
                  value={values.jobDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </span>
            </div>

            <div className="db">
              <div className="pb2 dib w-40 mr2">
              <label htmlFor="effectiveDate" className="f6 fw5 db mb2 mt3">Effective Date</label>
                <DayPickerInput
                  inputProps={{ name: 'effectiveDate' }}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={FORMAT}
                  dayPickerProps={{ disabledDays: { before: today } }}
                  placeholder={`${dateFnsFormat(today, FORMAT)}`}
                  onDayChange={(date) => (
                    setFieldValue('effectiveDate', date)
                  )}
                />
              </div>
              <div className="pb2 dib w-40 mr2">
              <label htmlFor="endDate" className="f6 fw5 db mb2 mt3">End Date</label>
                <DayPickerInput
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={FORMAT}
                  dayPickerProps={{ disabledDays: { before: values.effectiveDate, after: oneYear } }}
                  placeholder={`${dateFnsFormat(tomorrow, FORMAT)}`}
                  onDayChange={(date) => (
                    setFieldValue('endDate', date)
                  )}
                />
              </div>
            </div>

            <div className="pb2">
              <span className="w-100">
                <label htmlFor="jobWage" className="f6 fw5 db mb2 mt3">Wage</label>
                <div className="w-100 relative mv3 dt dib">
                  <div className="b--black-10 bb bt bl pa2 br2 br--left dtc dib black-50 w1">$</div>
                    <Field name="jobWage"
                      style={getStyles(errors, 'jobWage', touched)}
                      className="dtc dib bt bb outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 br--right"
                      type="text"
                      placeholder="e.g., 5000"
                      value={values.jobWage}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="fr mt3">
        <button
          type="submit"
          disabled={isSubmitting}
          className="f6 link dim br2 ph3 pv2 dib white bg-blue hover-bg-blue-80 bn pointer"
        >
          Create
        </button>
      </div>
    </Form>
  )
}

function JobAssignmentModal(props){
  const { data } = props;
  
  const { loading: jobCategoryLoading, data: categoryCodeData } = useQuery(JOB_CATEGORY_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    notifyOnNetworkStatusChange: true,
  });

  const [createQuote, { loading: createQuoteLoading, error: createQuoteError, reset: createQuoteReset}] = useMutation(CREATE_QUOTE, {
    onCompleted: (data)=> {
      if (!data.createQuote?.ok){
        props.setErrorMessage(true)
        createQuoteReset()
      } else {
        props.closeModal();
        props.setNewApplicationRecords({'node': data.createQuote?.quote})
        props.setSuccessMessage(data.createQuote?.message)
        props.setErrorMessage(false)
        createQuoteReset()
      }
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  const [createAssignment, { loading: createAssignmentLoading, error, reset: createAssignmentReset }] = useMutation(CREATE_ASSSIGNMENT, {
    onCompleted: (data)=>{
      if (!data.createAssignment?.ok){
        props.setErrorMessage(true)
        createAssignmentReset()
      } else {
        props.closeModal();
        props.setNewAssignments({'node': data.createAssignment?.assignment})
        props.setSuccessMessage(data.createAssignment?.message)
        props.setErrorMessage(false)
        createAssignmentReset()
      }
    },
    onError: (error) => {
      console.log(error)
      props.setErrorMessage(true)
      createAssignmentReset()
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  const getQuoteWithActivePolicy = (quotes) => {
    return quotes.find(obj => obj?.node?.policy?.edges[0]?.node?.isActive)
  }

  const initialQuote = getQuoteWithActivePolicy(data?.quotes?.edges)

  const quoteId = initialQuote?.node?.publicId
  const contractor = data?.publicId
  const jobCategoryCode = initialQuote?.node?.job?.jobCategory?.code
  const residenceState = initialQuote?.node?.residenceState
  const workState = initialQuote?.node?.workState
  
  const formInitialValues = {}
  formInitialValues.selectedPolicy = quoteId
  formInitialValues.contractor = contractor
  formInitialValues.effectiveDate = today
  formInitialValues.endDate = tomorrow
  formInitialValues.coverageTypes = "workers-comp"
  formInitialValues.workState = workState || "AL"
  formInitialValues.residenceState = residenceState || "AL"

  formInitialValues.jobName = ""
  formInitialValues.jobDescription = ""
  formInitialValues.jobCategoryCode = jobCategoryCode
  formInitialValues.jobWage = ""
  
  formInitialValues.entityName = ""
  formInitialValues.entityRequiredCoverage = ["workers-comp"]
  formInitialValues.entityAddressLine1 = ""
  formInitialValues.entityAddressLine2 = ""
  formInitialValues.entityAddressLine3 = ""
  formInitialValues.entityAddressLocality = ""
  formInitialValues.entityAddressRegion = ""
  formInitialValues.entityAddressPostalcode = ""
  formInitialValues.entityAdditionalInsuredMsg = ""

  function initValues(values){
    const job = {
      name: values.jobName,
      description: values.jobDescription,
      categoryCode: values.jobCategoryCode,
      wage: parseInt(values.jobWage)
    }

    const requiredCoverageWc = {
      coverageType: values.entityRequiredCoverage[0]
    }

    const address = {
      line1: values.entityAddressLine1,
      line2: values.entityAddressLine2,
      line3: values.entityAddressLine3,
      locality: values.entityAddressLocality,
      region: values.entityAddressRegion,
      postalcode: values.entityAddressPostalcode,
    }

    const entity = {
      name: values.entityName,
      address: address,
      requiredCoverage: [requiredCoverageWc]
    }

    return {
      job: job,
      entity: entity,
    }
  }

  function createNewQuote(values){
    const {
      job,
      entity
    } = initValues(values)
    
    createQuote({
      variables: {
        contractor: values.contractor,
        workState: values.workState,
        residenceState: values.residenceState,
        job: job,
        coverageType: values.coverageTypes,
        entity: entity,
        effectiveDate: values.effectiveDate,
        endDate: values.endDate,
      }
    })
  }

  function createJobAssignment(values){
    const {
      job,
      entity
    } = initValues(values)
    
    createAssignment({
      variables: {
        contractor: values.contractor,
        effectiveDate: values.effectiveDate,
        quote: values.selectedPolicy,
        endDate: values.endDate,
        job: job,
        entity: entity,
      }
    })
  }

  function createJobAssignmentOrQuote(values){
    if (values.selectedPolicy === 'new'){
      createNewQuote(values)
    } else {
      createJobAssignment(values)
    }
  }

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderModalBody = () => (
    <div className="roboto">
        <PageHeader title={'Create Job Assignment'} />
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, {setSubmitting, setTouched})=> {
            setTouched({ 
              entityName: true, 
              jobName: true, 
              jobDescription: true, 
              jobWage: true
            }) // Mark all fields as touched on submit
            setSubmitting(true)
            createJobAssignmentOrQuote(values)
          }}
          validationSchema={Yup.object().shape({
            contractor: Yup.string().required('Required'),
            jobName: Yup.string().required('Required'),
            jobDescription: Yup.string().required('Required'),
            jobWage: Yup
              .number("Must be a number type") 
              .positive("Must be a positive value")
              .required("Please enter a wage. The field cannot be blank.")
              .min(1, "The wage must be greater than or equal to 1"),
            effectiveDate: Yup.string().required('Required'),
            endDate: Yup.string().required('Required'),
            entityName: Yup.string().required('Required')
          })}
        >{(formikProps) => (
          <JobAssignmentForm {...props} {...formikProps} categoryCodeData={categoryCodeData}/>
        )}
        </Formik>
      </div>
  )
  
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel={props.contentLabel}
    >
      {(jobCategoryLoading || createAssignmentLoading || createQuoteLoading) ? renderLoading() : renderModalBody()}
    </Modal>
  )
}

function ContractorSideNav(props){
  let [idCopied, setIdCopied] = useState()
  
  const {
    errors,
    touched,
    isActiveEdit,
    data
  } = props
  
  const withholdPremiumOrgOverride = data?.withholdPremiumOrgOverride
  const withholdPremium = data?.withholdPremium
  
  const withholdPremiumMsg = withholdPremium || withholdPremiumOrgOverride ? 'True' : 'False'
  
  function afterHideTip() {
    setIdCopied(false)
  }

  return (
    <header className="fn fl-ns w-25-ns mb3">
      <table cellSpacing="0">
        <tbody>
        <tr>
          <td className="pr4">
            <div className="fl pb2 pr2">
              <Field name="firstName"
                style={getStyles(errors, 'firstName', touched)}
                placeholder="First name"
                font="24px Roboto"
                fontWeight="600"
                padding={10}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f3 fw6 pv2", 
                {"ba b--black-10 br2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            <div className="fl pb2">
              <Field name="lastName" 
                style={getStyles(errors, 'lastName', touched)}
                placeholder="Last name"
                font="24px Roboto"
                fontWeight="600"
                padding={10}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f3 fw6 pv2",
                {"ba b--black-10 br2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            <div className="fw2 f6 gray">
              <Field name="email" 
                style={getStyles(errors, 'email', touched)}
                placeholder="Email"
                font="14px Roboto"
                fontWeight="300"
                minwidth={240}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f6 fw3", 
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <table cellSpacing="0">
        <tbody className="lh-copy f6 fw2 gray">
          <tr>
            <td className="pb3 pr4">
            {
                (isActiveEdit || (!isActiveEdit && data?.address?.line1)) && 

                <div className="mt1">
                  <Field name="line1" 
                    placeholder="Line 1"
                    font="14px Roboto"
                    fontWeight="300"
                    minwidth={240}
                    component={AutoSizingInputAdvanced}
                    className={classNames("outline-0 f6 fw3", 
                    {"ba b--black-10 br2 pv2": isActiveEdit},
                    {"ba b--transparent": !isActiveEdit})}
                    type="text" 
                    autoComplete="off"
                    readOnly={!isActiveEdit}/>
                </div>
            }

            {
                (isActiveEdit || (!isActiveEdit && data?.address?.line2)) &&
                
                <div className="mt1">
                  <Field name="line2" 
                    placeholder="Line 2"
                    font="14px Roboto"
                    fontWeight="300"
                    minwidth={240}
                    component={AutoSizingInputAdvanced}
                    className={classNames("outline-0 f6 fw3", 
                    {"ba b--black-10 br2 pv2": isActiveEdit},
                    {"ba b--transparent": !isActiveEdit})}
                    type="text" 
                    autoComplete="off"
                    readOnly={!isActiveEdit}/>
                </div>
            }

            {
                (isActiveEdit || (!isActiveEdit && data?.address?.line3)) &&

                <div className="mv1">
                  <Field name="line3" 
                    placeholder="Line 3"
                    font="14px Roboto"
                    fontWeight="300"
                    minwidth={240}
                    component={AutoSizingInputAdvanced}
                    className={classNames("outline-0 f6 fw3", 
                    {"ba b--black-10 br2 pv2": isActiveEdit},
                    {"ba b--transparent": !isActiveEdit})}
                    type="text" 
                    autoComplete="off"
                    readOnly={!isActiveEdit}/>
                </div>
            }
            
            <div>
              <Field name="locality" 
                placeholder="City"
                font="14px Roboto"
                fontWeight="300"
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 dib f6 fw3",
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>

              {
                isActiveEdit ?
                <Field name="region"
                  component="select" 
                  className={classNames("outline-0 dib f6 fw3 ml2", 
                  {"ba b--black-10 br2 pv2": isActiveEdit},
                  {"ba b--transparent": !isActiveEdit})}
                  readOnly={!isActiveEdit}
                >
                  {states.map(state => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))}
                </Field> : <div className="dib black">{data?.address?.region}</div>
              }

              <Field name="postalcode" 
                placeholder="Zip code"
                font="14px Roboto"
                fontWeight="300"
                minwidth={70}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 dib f6 fw3  ml2", 
                {"ba b--black-10 br2  pa2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>

            </td>
          </tr>
        </tbody>
      </table>
      <div className="fw2 f6 gray mv3">
        <div className="pa1">Agency Remit: 
          {
            isActiveEdit ?
              <Field name="withholdPremium" 
              component="select" 
              className={classNames("outline-0 ml1 dib f6 fw3", 
              {"ba b--black-10 br2 pv2": isActiveEdit},
              {"ba b--transparent": !isActiveEdit})}
              disabled={withholdPremiumOrgOverride }>
                <option value="true">True</option>
                <option value="false">False</option>
              </Field> : <div className="pl1 dib gary">{withholdPremiumMsg}</div>
          }

          { withholdPremiumOrgOverride && 
            <span className="f7 pt2 black-40 db">
              Org-level agency remit is set to "True"
            </span> 
          }
        </div>
        <div>
          {
            (isActiveEdit || (!isActiveEdit && data?.poContactName)) && 

            <div className="mt1">
              <Field name="poContactName" 
                placeholder="PO Contact Name"
                font="12px Roboto"
                fontWeight="300"
                minwidth={240}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f6 fw3", 
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent black-40": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            }

            {
            (isActiveEdit || (!isActiveEdit && data?.poContactEmail)) && 

            <div className="mt1">
              <Field name="poContactEmail" 
                placeholder="PO Contact Email"
                font="12px Roboto"
                fontWeight="300"
                minwidth={240}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f6 fw3", 
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent black-40": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            }
        </div>
        
        
      </div>
      <CopyToClipboard text={data.publicId} onCopy={() => setIdCopied(true)}>
        <div data-tip data-for='contractor_id_copy' className="mv3 fw2 f6 gray ba br2 lh-copy dib pv1 ph2 b--black-10 pointer">
          {data.publicId}
        </div>
      </CopyToClipboard>
      <ReactTooltip 
        id='contractor_id_copy' 
        place='bottom'
        type='info'
        afterHide={afterHideTip} 
        effect='solid'>
        <span>{idCopied ? 'Copied!' : 'Click to copy'}</span>
      </ReactTooltip>
    </header>
  )
}

function MarkExceptionOnHoverBtn(props){
  const [hover, setHover] = useState(false);
  
  const {
    url,
    status,
    isEligible,
    markOfacUserException,
    loadingOfac,
    loadingSpinner,
    isAdmin
  } = props

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const adminOnlyExceptionBtn = () => {
    return (
      <button 
        type="button" 
        onClick={() => markOfacUserException(url)}
        className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100 red">
        {loadingOfac ? 
          loadingSpinner() : 
          'Mark exception'
        }
      </button>
    )
  }

  const renderStatus = () => (
    <>
      {!isEligible ? (<span className="w-100 lh-title red">Ineligible</span>): status }
    </>
  )

  return (
    <div
      className="dib w-100"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex="-3"
    >
      {hover && isAdmin && !isEligible ? adminOnlyExceptionBtn() : renderStatus()}
    </div>
  )
}

function CoverageCancelOnHoverBtn(props){
  const [hover, setHover] = useState(false);

  const { 
    effectiveDate, 
    isAdmin,
    policyPublicId,
    isActive,
    setCancelPolicyModalValues 
  } = props

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const adminOnlyCancelBtn = () => {
    return (
      <button 
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          setCancelPolicyModalValues({cancelPolicyModalIsOpen: true, policy: policyPublicId, confirmed: false })
        }}
        className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
        <img className="dib v-btm w1" src="/cancel.svg" alt="Cancel" /> Cancel Policy
      </button>
    )
  }

  const renderActiveAsOf = () => (
    <button className="outline-0 br2 ba b--black-20 bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
      {effectiveDate}
    </button>
  )

  return (
    <div
      className="dib w-100"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex="-3"
    >
      {hover && isAdmin && isActive ? adminOnlyCancelBtn() : renderActiveAsOf()}
    </div>
  )
}

function AssignmentCancelHoverButton(props){
  /* 
  * TODO: Update once we've finalized options
  * for different types of cancellations.
  * 
  * https://docs.google.com/spreadsheets/d/1brggufSlYvvmcct_MXmn1WPeYsAy5dZUKABHXoI7aRM/edit?usp=sharing
  */
  const [hover, setHover] = useState(false);

  const { 
    coverageDates,
    isCancelled,
    isAdmin,
    jobPublicId,
    setCancelAssignmentModalValues
  } = props
  
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const adminOnlyCancelBtn = () => {
    return (
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          setCancelAssignmentModalValues({cancelAssignmentModalIsOpen: true, assignment: jobPublicId, confirmed: false })
        }}
        className={classNames(
          "outline-0 pointer br2 ba b--black-20 gray bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100",
        )}
      > <div className="flex items-center"> 
        <img className="dib v-btm w1 tr" src="/cancel.svg" alt="Cancel" /> 
          <div>
            <div className="f7 tl pl2">Cancel</div>
            <div className="f7 tl pl2">Coverage</div>
          </div>
        </div>
      </button>
    )
  }

  const renderCoverageDates = () => (
    <button className="outline-0 br2 ba b--black-20 gray bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
      {coverageDates}
    </button>
  )

  const renderCancelledText = () => (
    <span className="outline-0 br2 ba b--black-20 gray bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
      Cancelled
    </span>
  )

  if (isCancelled){
    return renderCancelledText()
  }

  return (
    <div
      className="dib w-100"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex="-3"
    >
      {hover && false && !isCancelled ? adminOnlyCancelBtn() : renderCoverageDates()}
    </div>
  )
}

function ContractorMain(props){
  let [urlCopied, setUrlCopied] = useState()
  let [markExceptions, setMarkExceptions] = useState([])

  const {
    data, 
    isAdmin, 
    isActiveEdit, 
    values, 
    setValues, 
    setFieldValue,
    errors, 
    touched,
  } = props
  
  const newApplicationUrls = props.newApplicationUrls
  
  const NOT_STARTED = "Not started."
  const IN_PROGRESS = "In progress"
  const COMPLETE = "Complete"
  const INELIGIBLE = "Ineligible"

  const APPROVED = "APPROVED"
  const DENIED = "DENIED"
  
  let allQuotes = data?.quotes?.edges
  let allAssignments = data?.assignments?.edges
  let allExternalCertificates = data?.externalCertificates?.edges
  
  let allCertificateAudits = allExternalCertificates.flatMap(
    (externalCertificate) => externalCertificate.node.certificateAudits.edges.map(
      (auditEdge) => auditEdge.node
    )
  )

  const filteredCertificates = allCertificateAudits.filter(
    (certificate) => certificate?.status === APPROVED || certificate?.status === DENIED
  );

  const newApplicationRecords = props.newApplicationRecords
  const insuranceApplications = getApplications(newApplicationRecords ? allQuotes.concat(newApplicationRecords) : allQuotes)
  
  const policies = getPolicies(allQuotes)
  
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  
  const newAssignments = props.newAssignments
  const jobsArray = useMemo(() => 
    buildJobHistory(allQuotes, allAssignments), 
    [allQuotes, allAssignments, newAssignments]);
  
  //Update formik values
  useEffect(() => {
    setValues({...values, jobs: jobsArray})
  }, [jobsArray])
  
  const confirmedCancelledPolicies = props.confirmedCancelledPolicies

  const [wageEditModalIsOpen, setWageEditModalIsOpen] = useState(false)
  const [wageEditedAssignment, setWageEditedAssignment] = useState(null)

  const [expireRenewApplication, { loading: loadingUrl, error: errorUrl, reset: resetUrl }] = useMutation(EXPIRE_RENEW_APPLICATION, {
    onCompleted: (data) => {
      if (!data.expireRenewInsuranceApplication?.ok){
        props.setErrorMessage(data.expireRenewInsuranceApplication?.message)
        resetUrl()
      } else {
        props.setNewApplicationUrls([{
          'quoteId': data.expireRenewInsuranceApplication?.quoteId,
          'url': data.expireRenewInsuranceApplication?.url
        }, ...newApplicationUrls])
        props.setSuccessMessage(data.expireRenewInsuranceApplication?.message)
        props.setErrorMessage(false)
        resetUrl()
      }
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  function expireInsuranceApplication(insurance_application_url){
    expireRenewApplication({
      variables: {
        insuranceApplicationUrl: insurance_application_url,
      }
    })
  }

  const [markOfacUserExceptionRequest, {loading: loadingOfac, error: errorOfac, reset: resetOfac}] = useMutation(MARK_OFAC_USER_EXCEPTION, {
    onCompleted: (data) => {
      if (!data.markOfacUserException?.ok){
        props.setErrorMessage(data.markOfacUserException?.message)
        resetOfac()
      } else {
        props.setSuccessMessage(data.markOfacUserException?.message)
        setMarkExceptions([{
          'quoteId': data.markOfacUserException?.quoteId,
          'isException': true
        }, ...markExceptions])
        resetOfac()
      }
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  function markOfacUserException(insurance_application_url){
    markOfacUserExceptionRequest({
      variables: {
        insuranceApplicationUrl: insurance_application_url,
      }
    })
  }

  const loadingSpinner = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} width={14} height={14} />
  )

  
  function getApplicationStatus(quote, insuranceApplication){
    let status = NOT_STARTED

    const quoteId = quote?.node?.publicId    
    const policy = quote?.node?.policy?.edges[0]?.node

    const newAppUrl = newApplicationUrls.length > 0 ? newApplicationUrls.find(record => record['quoteId'] === quoteId) : null;
    const applicationStarted = !newAppUrl && insuranceApplication?.applicationStarted

    const isException = markExceptions.length > 0 ? markExceptions.find(record => record['quoteId'] === quoteId) : null;
    
    if (!insuranceApplication?.isEligible && !isException){
      status = INELIGIBLE
    }

    if (policy){
      status = COMPLETE 
    } else if(applicationStarted){
      status = IN_PROGRESS
    } 

    return status
  }

  function getApplications(quotes){
    let applicationList = [];

    applicationList = quotes
      .map(obj => {
        const newObj = {
          ...obj?.node.insuranceApplication,
          ['status']: getApplicationStatus(obj, obj?.node.insuranceApplication),
          ['quoteId']: obj?.node.publicId,
          ['jobCategory']: obj?.node.job?.jobCategory?.className,
          ['coverageTypes']: shortCoverageNames(obj?.node?.coverageType?.edges),
        }
        
        const newAppUrl = newApplicationUrls.find(record => record['quoteId'] === obj?.node.publicId)
        
        if(newAppUrl){
          newObj['url'] = newAppUrl['url']
        }
  
        return newObj
      })
      .filter(value => value.status !== COMPLETE)
      .filter(value => value.url !== null && value.url !== undefined)
      .filter(value => value !== null && value !== undefined)
    
    return applicationList
  }

  const renderInsuranceApplicationUrl = (applicationUrl) => {
    return (
      <div className="gray f7 fw2 lh-copy">
        <div className="cf relative">
          <CopyToClipboard text={applicationUrl} onCopy={() => setUrlCopied(true)}>
            <div data-tip data-for='url_copy' className="dib pointer f7 fw5 fl bg-white pv2 lh-copy">
            {applicationUrl}
            </div>  
          </CopyToClipboard>
          <ReactTooltip 
            id='url_copy' 
            place='bottom'
            type='info'
            afterHide={afterHideUrlTip} 
            effect='solid'>
            <span>{urlCopied ? 'Copied!' : 'Click to copy'}</span>
          </ReactTooltip>
        </div>      
      </div>
    )
  }
  
  function afterHideUrlTip() {
    setUrlCopied(false)
  }

  const renderApplicationRows = (applications) => {
    function isComplete(status){
      return [COMPLETE].includes(status)
    }

    function isGreenStatus(status){
      return [COMPLETE, IN_PROGRESS].includes(status)
    }

    const rows = applications.map((obj) => {
      return (
        <tr className="bb" key={obj?.quoteId}>
          <td className="tl ph2 pv3 f7">{startAndEnd(obj?.jobCategory)} ({obj?.coverageTypes})</td>
          <td className="tl ph2 pv3 f7">{renderInsuranceApplicationUrl(obj?.url)}</td>
          <td className={classNames("tl ph2 pv3 f7", {"green": isGreenStatus(obj?.status)}, {"red": !isGreenStatus(obj?.status)})}>
            <MarkExceptionOnHoverBtn 
              url={obj?.url}
              status={obj?.status}
              isEligible={obj?.isEligible}
              markOfacUserException={markOfacUserException}
              loadingOfac={loadingOfac}
              loadingSpinner={loadingSpinner}
              isAdmin={isAdmin}
            />
          </td>
          <td className="tl ph2 pv3 f7">{
              isAdmin && !isComplete(obj?.status) && 
              (
                <button 
                  type="button" 
                  onClick={() => expireInsuranceApplication(obj?.url)}
                  className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 f7 lh-title bg-animate hover-bg-light-gray border-box">
                  {loadingUrl ? 
                    loadingSpinner() : 
                    <><img className="dib v-btm w1" src="/refresh.svg" alt="Refresh" /></>
                  }  
                </button>
              )
            }
          </td>
        </tr>
      )
    })

    return rows
  }

  const renderApplicationTable = (applications) => {
    return (
      <div>
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
        <thead>
          <tr className="bb b--light-gray">
            <th className="tl pv3 ph2 fw5 f7 ttu w-20">Job Category</th>
            <th className="tl pv3 ph2 fw5 f7 ttu">URL</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Status</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">
            </th>
          </tr>
        </thead>
        <tbody>
          {renderApplicationRows(applications)}
        </tbody>
        </table>
      </div>
    )
  }

  const renderActiveCoverageRows = (policies) => {
    const rows = policies.map((obj) => {
      const policy = obj?.edges[0]?.node
      const jobCategory = obj?.jobCategory

      const coverageTypes = obj?.coverageTypes
      const effectiveDate = new Date(policy?.effectiveDate).toLocaleDateString("en-US", options);
      const cancelledDate = policy?.cancelledDate ? new Date(policy?.cancelledDate).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'}) : undefined;
      const esignPdfUrl = policy?.esignDisclosures?.edges[0]?.node?.esignPdfUrl
      
      const confirmedCancelledPolicy = confirmedCancelledPolicies.includes(policy?.publicId)
      const policyIsActive = policy?.isActive && !confirmedCancelledPolicy
      
      return (
        <tr className="bb" key={policy?.publicId}>
          <td className="tl ph2 pv3 f7">{policy?.publicId}</td>
          <td className="tl ph2 pv3 f7">{startAndEnd(jobCategory)} </td>
          <td className="tl ph2 pv3 f7"> {coverageTypes} </td>
          <td className="tl ph2 pv3 f7">
            {policyIsActive ? 
              <span className="tl f7 mv1 ph2 dib green">Active</span> :
              <div className="cf relative">
                <span className="tl f7 mv1 ph2 dib red">Cancelled
                  {cancelledDate &&  <span className="db red tc" style={{fontSize: '10px'}}>{`${cancelledDate}`}</span>}
                </span>
              </div>
            }
          </td>
          <td className="tl ph2 pv3 f7">
            <CoverageCancelOnHoverBtn 
              policyPublicId={policy?.publicId}
              isActive={policyIsActive}
              setCancelPolicyModalValues={props.setCancelPolicyModalValues}
              effectiveDate={effectiveDate} 
              isAdmin={isAdmin} 
            />
          </td>
          <td className="tl ph2 pv3 f7 tc">
            { esignPdfUrl ?
            <a className="dark-gray" href={esignPdfUrl} target="_blank" rel="noopener noreferrer">
              <img className="dib v-btm pr1 ph3 w1" src="/document.svg" alt="e-signature" />
            </a> : null
            }
          </td>
        </tr>
      )})

    return rows
  }

  const renderActiveCoverageTable = (policies) => {
    return (
      <div>
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
        <thead>
          <tr className="bb b--light-gray">
          <th className="tl pv3 ph2 fw5 f7 ttu w-10">Policy ID</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-25">Job Category</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Coverage (Work / Home)</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Status</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Active as of</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15 tc">e-Signature</th>
          </tr>
        </thead>
        <tbody>
          {renderActiveCoverageRows(policies)}
        </tbody>
        </table>
      </div>
    )
  }

  function getCertificateReviewCoverageDetails(data){
    if (!data?.coverages) {
      return []
    }

    const { commercial_general_liability, workers_compensation } = data.coverages;
    let result = [];

    if (commercial_general_liability && commercial_general_liability.policy_number_string) {
      const glStartDate = commercial_general_liability.policy_effective_date
      const glEndDate = commercial_general_liability.policy_expiration_date

      result.push({
        policyType: `GL`,
        validThru: `${glStartDate} – ${glEndDate}`
      });
    }

    if (workers_compensation && workers_compensation.policy_number_string) {
      const wcStartDate = workers_compensation.policy_effective_date
      const wcEndDate = workers_compensation.policy_expiration_date
      
      result.push({
        policyType: `WC`,
        validThru: `${wcStartDate} – ${wcEndDate}`
      });
  }
  return result;

  }

  const renderReviewedCertificateRows = () => {
    function getCoverageLabels(data) {
      const policyTypes = data.map(item => item.policyType);
      return policyTypes.join(', ');
    }

    function getCoverageValidThru(data) {
      const validThruDates = data.map(item => item.validThru);
      return validThruDates.join(', ');
    }

    const rows = filteredCertificates.map((reviewedCertificate) => {
      const status = reviewedCertificate?.status
      const statusClass = classNames({
        'green': status === APPROVED,
        'red': status === DENIED
      });
      
      const parsedCetificate = JSON.parse(reviewedCertificate?.parsedCertificateJson)
      const coverageDetails = getCertificateReviewCoverageDetails(parsedCetificate)
      
      const pdfUrl = reviewedCertificate?.certificate?.pdfUrl

      return (
        <tr className="bb" key={reviewedCertificate?.publicId}>
          <td className="tl ph2 pv3 f7">{reviewedCertificate?.publicId}</td>
          <td className="tl ph2 pv3 f7 ttu">{getCoverageLabels(coverageDetails)}</td>
          <td className="tl ph2 pv3 f7 ttu">{getCoverageValidThru(coverageDetails)}</td>
          <td className={`tl ph2 pv3 f7 ttc ${statusClass}`}>{status.toLowerCase()}</td>
          <td className="tl ph2 pv3 f7 tc">
            { pdfUrl ?
            <a className="dark-gray" href={pdfUrl} target="_blank" rel="noopener noreferrer">
              <img className="dib v-btm pr1 ph3 w1" src="/document.svg" alt="certificate-of-insurance" />
            </a> : null
            }
          </td>
        </tr>
      )
    })

    return rows
  }

  const renderReviewedCertificateTable = (reviewedCertificates) => {
    return (
      <div>
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
        <thead>
          <tr className="bb b--light-gray">
          <th className="tl pv3 ph2 fw5 f7 ttu w-15">Review ID</th>
            <th className="tl pv3 ph2 fw5 f7 ttu">Coverage</th>
            <th className="tl pv3 ph2 fw5 f7 ttu">Coverage Period</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Status</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15 tc">Certificate</th>
          </tr>
        </thead>
        <tbody>
          {renderReviewedCertificateRows(reviewedCertificates)}
        </tbody>
        </table>
      </div>
    )
  }

  const renderWorkHistoryRows = (jobs) => {
    const usStates = states
    function coveragePeriod(effectiveDate, endDate){
      const start = new Date(effectiveDate)
      const end = new Date(endDate)
      return `${start.toLocaleDateString('en-US')} – ${end.toLocaleDateString('en-US')}`
    }

    function downloadCertificateLink(coverageType, pdfUrl){
      return (
        <a className="dark-gray" href={pdfUrl} target="_blank" rel="noopener noreferrer">{coverageType}</a>
      )
    }
    
    const rows = jobs.map((obj, index) => {
      const isAssignment = obj?.node?.__typename == 'Assignment'
      const hasPolicy = obj?.node?.policy?.edges?.length > 0
      const cancelled = isAssignment ? obj?.node?.cancelled : null;
      
      const residenceState = isAssignment ? obj?.node?.residenceState : (obj?.node?.quoteJsonResidenceState || obj?.node?.residenceState)
      const workState = isAssignment ? obj?.node?.workState : obj?.node?.quoteJsonWorkState

      const states = [workState, residenceState]
      const filteredStates = states.filter(state => state !== null).join(' / ');

      const certNode = isAssignment ? obj?.node?.certificate?.edges[0]?.node : obj?.node?.policy?.edges[0]?.node
      const hasUpcomingPayment = isAssignment ? obj?.node?.hasUpcomingPayment : null

      const wcPdfUrl = certNode?.wcCoiPdfUrl ? certNode?.wcCoiPdfUrl : null;
      const glPdfUrl = certNode?.glCoiPdfUrl ? certNode?.glCoiPdfUrl : null;
      const pdfUrl = !wcPdfUrl && !glPdfUrl ? certNode?.pdfUrl : null;
      const copcUrl = certNode?.copcUrl ? certNode?.copcUrl : null;

      const assignmentEligibilityStatus = obj?.node?.eligibilityStatus ? JSON.parse(obj?.node?.eligibilityStatus) : null
      const {setCancelAssignmentModalValues} = props;
      
      const FORMAT = 'MM/dd/yyyy';
      const today = new Date()

      return (
        <tr className="bb assignment-table-row" key={obj?.node?.job?.publicId}>
          <td className="tl ph2 pv3 f7">
            <Field name={`jobs[${index}].node.job.entity.name`} 
                style={getStyles(errors, `jobs[${index}].node.job.entity.name`, touched)}
                font="12px Roboto"
                fontWeight="300"
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 dib f7 fw3",
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent gray": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
          </td>
          <td className="tl ph2 pv3 f7">
            {obj?.node?.job?.name} ({obj?.node?.job?.publicId}) &nbsp;
            {
              isAssignment && hasUpcomingPayment &&
              <span className="f7 ba br-pill mv1 ph3 dib blue bg-washed-blue b--blue">
                Pmt due {moment.utc(obj?.node?.creditCardChargeDatetime).from(moment.utc())}
              </span>
            }
            {
              assignmentEligibilityStatus && assignmentEligibilityStatus?.result === false && !hasPolicy &&
              <span className="f7 ba br-pill mv1 ph3 dib red bg-washed-red b--red">Ineligible</span>
            }
            {
              !isAssignment && !hasPolicy &&
              <span className="f7 ba br-pill mv1 ph3 dib gray bg-washed-gray b--gray">Pending</span>
            }
          </td>
          <td className="tl ph2 pv3 f7">
            {isActiveEdit ? 
              <>
                <Field name={`jobs[${index}].node.workState`} component="select" className="outline-0 dib f7 fw3 ba b--black-10 br2 pv1">
                  {usStates.map(state => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))}
                </Field> 
                <span> / {residenceState}</span>
              </>: filteredStates
            }
          </td>
          <td className="tl ph2 pv3 f7">
          {isActiveEdit ?
            <>
              <Field name={`jobs[${index}].node.effectiveDate`}
                className="outline-0 dib f7 fw3 ba b--black-10 br2 pv1"
                readOnly={!isActiveEdit}
              >
                {({ field, form }) => (
                  <DayPickerInput
                    field={field}
                    form={form}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format={FORMAT}
                    value={dateFnsFormat(new Date(field.value), FORMAT)}
                    onDayChange={(date) => {
                      setFieldValue(`jobs[${index}].node.effectiveDate`, date)
                    }}
                    className="ba b--black-10 f7"
                    placeholder={`${dateFnsFormat(today, FORMAT)}`}
                  />
                )}
              </Field>
              <Field name={`jobs[${index}].node.endDate`}
                className="outline-0 dib f7 fw3 ba b--black-10 br2 pv1"
                readOnly={!isActiveEdit}
              >
                {({ field, form }) => (
                  <DayPickerInput
                    field={field}
                    form={form}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format={FORMAT}
                    value={dateFnsFormat(new Date(field.value), FORMAT)}
                    onDayChange={(date) => {
                      setFieldValue(`jobs[${index}].node.endDate`, date)
                    }}
                    className="ba b--black-10 f7"
                    placeholder={`${dateFnsFormat(today, FORMAT)}`}
                  />
                )}
              </Field>
            </> : 
            <AssignmentCancelHoverButton 
              jobPublicId={obj?.node?.job?.publicId}
              coverageDates={coveragePeriod(obj?.node?.effectiveDate, obj?.node?.endDate)}
              isCancelled={cancelled}
              setCancelAssignmentModalValues={setCancelAssignmentModalValues}
              isAdmin={isAdmin} 
            />
          }
          </td>
          <td className="tl ph2 pv3 f7 relative">
          {isActiveEdit ? 
              <Field 
                name={`jobs[${index}].node.job.wage`} 
                style={getStyles(errors, `jobs[${index}].node.job.wage`, touched)}
                component={CurrencyInput}
                className="outline-0 dib f7 fw3 ba b--black-10 br2 pv2 w-100"
              /> : 
              obj?.node?.invoice?.grossPay && !cancelled ? 
                <div className="relative wage-viewport">
                  <div className="wage-container absolute pt1">
                    {centsToDollars(obj?.node?.invoice?.grossPay)}
                  </div>
                  <div className="edit-button-container absolute">
                    <button
                      className="db v-mid outline-0 pointer br2 ba b--black-20 bg-white pa1 ml0 f7 lh-title bg-animate hover-bg-light-gray border-box"
                      onClick={() => {
                        setWageEditModalIsOpen(true);
                        setWageEditedAssignment(obj?.node);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              : centsToDollars(obj?.node?.job?.wage)
            }
          </td>
          <td className="tc ph2 pv3 f7">
            { obj?.node?.totalPremiumOwed ? centsToDollars(obj?.node?.totalPremiumOwed) : '---'}
          </td>
          <td className="tl ph2 pv3 f7">
            {wcPdfUrl && downloadCertificateLink('WC', wcPdfUrl)} &nbsp;
            {glPdfUrl && downloadCertificateLink('GL', glPdfUrl)}
            {pdfUrl && downloadCertificateLink('WC', pdfUrl)}
          </td>
          {isAdmin && 
            <td className="tl ph2 pv3 f7">
              {copcUrl && downloadCertificateLink('COPC', copcUrl)}
            </td>
          }
        </tr>
      )
    })
    return rows
  }

  const renderWorkHistoryTable = (jobs) => {
    return (
      <div>
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
        <thead>
          <tr className="bb b--light-gray">
          <th className="tl pv3 ph2 fw5 f7 ttu w-15">Client</th>
          <th className="tl pv3 ph2 fw5 f7 ttu w-20">Assignment</th>
          <th className="tl pv3 ph2 fw5 f7 ttu w-10">Work / Home</th>
          <th className="tl pv3 ph2 fw5 f7 ttu w-20">Coverage Period</th>
          <th className="tl pv3 ph2 fw5 f7 ttu w-10">Wage</th>
          <th className="tl pv3 ph2 fw5 f7 ttu w-10">Premium</th>
          <th className="tl pv3 ph2 fw5 f7 ttu w-10">COI</th>
          {isAdmin && 
            <th className="tl pv3 ph2 fw5 f7 ttu">Placement<br/>Confirmation</th>  
          }
          </tr>
        </thead>
        <tbody>
          {renderWorkHistoryRows(jobs)}
        </tbody>
        </table>
      </div>
    )
  }

  function renderWageEditModal() {
    const [wageValue, setWageValue] = useState('');

    const loadingSpinner = () => (
      <ReactLoading type={'spin'} color={'#cccccc'} width={24} height={24} />
    )

    const UpdateWageButton = (props) => {
      if (props.submitting){
        return (
          <button className="button-reset bg-animate white mt0 bn ttu pa3 w-100 mb2 hover-white br2">
            <ReactLoading type={'spin'} color={'#cccccc'} height={20} width={20} className="center" />
          </button>
          )
      } else {
        return (
          <button
            type="submit"
            className="f6 link dim br2 ph3 pv2 dib white bg-blue hover-bg-blue-80 bn pointer"
          >
            Update
          </button>
        )
      }
    }

    const handleInputChange = (e) => {
      setWageValue(e.target.value);
    };

    const closeWageModal = () => {
      setWageEditModalIsOpen(false)
      setWageEditedAssignment(null)
    }

    const { data: invoiceHistoryData, loading: invoiceHistoryLoading, error: invoiceHistoryError } = useQuery(ASSIGNMENT_INVOICE_HISTORY, {
      variables: { jobId: wageEditedAssignment?.job?.publicId },
      skip: !wageEditModalIsOpen,
      onCompleted: (data) => {
        const invoiceHistoryData = data.assignmentInvoiceHistory;
        const latestInvoiceGrossPay = invoiceHistoryData[invoiceHistoryData.length - 1]?.grossPay;
        if (latestInvoiceGrossPay) {
          setWageValue(centsToDollars(latestInvoiceGrossPay, true));
        }
      },
    });

    const [updateAssignmentWage, { loading, error, reset }] = useMutation(UPDATE_ASSIGNMENT_WAGE, {
      onCompleted: () => {
        setTimeout(() => {
          closeWageModal()
        }, 750);

        reset()
      },
      refetchQueries: ['AssignmentInvoiceHistory']
    });

    const handleSubmit = (values) => {
      updateAssignmentWage({
        variables: {
          jobId: wageEditedAssignment?.job?.publicId,
          contractorId: data?.publicId,
          newWage: parseInt(wageValue),
        }
      })
    };

    const customModalStyles = {
      ...modalStyles,
      content: {
        ...modalStyles.content,
        width: '1000px'
      }
    };

    return (
      <Modal
        isOpen={wageEditModalIsOpen}
        onRequestClose={closeWageModal}
        style={customModalStyles}
        contentLabel="Edit Wage"
      >
        <Formik
          initialValues={{ newWage: '' }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className='roboto relative invoice-history-modal'>
                <h3 className="dark-gray bb b--black-10 mt0 pb3">
                  Edit Job Wage
                </h3>

                <div>
                  {invoiceHistoryLoading ? (
                    loadingSpinner()
                  ) : invoiceHistoryError ? (
                    <p>Error loading invoice history</p>
                  ) : (
                    invoiceHistoryData && invoiceHistoryData.assignmentInvoiceHistory.length > 0 ? (
                      <div className="mv4">
                        <h4 className="f5 fw6">Invoice History</h4>

                        <div>
                          <table className="f6 w-100 mw8 center ba br3 b--solid b--black-10" cellSpacing="0">
                            <thead>
                              <tr>
                                <th className="fw6 tl pa3">Invoice</th>
                                <th className="fw6 tl pa3">Gross Pay</th>
                                <th className="fw6 tl pa3">Premium Due</th>
                                <th className="fw6 tl pa3">Created</th>
                              </tr>
                            </thead>
                            <tbody className="lh-copy" >
                              {invoiceHistoryData.assignmentInvoiceHistory.map((record) => (
                                <tr key={record.publicId} className={record.voided ? 'voided relative' : 'relative'}>
                                  <td className="pa3 relative br bt b--black-10">
                                    <div>
                                      {record.voided && (
                                        <div>
                                          <div className="stripes"></div>
                                          <span className="red ttu f6 br2 db fw6 lh-title h1 voided-badge">voided</span>
                                        </div>
                                      )}
                                      {!record.voided && record.paidInFull && (
                                        <span className="green ttu f6 br2 db fw6 lh-title h1 paid-in-full-badge">
                                          paid
                                        </span>
                                      )}
                                      {record.publicId}
                                    </div>
                                  </td>
                                  <td className="pa3 br bt b--black-10">{centsToDollars(record.grossPay)}</td>
                                  <td className="pa3 br bt b--black-10">{centsToDollars(record.premiumDue)}</td>
                                  <td className="pa3 br bt b--black-10">
                                    <span className="db">{moment.utc(record.created).local().fromNow()}</span>
                                    <small className="db f7 black-40">{record.created}</small>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <p>No invoice history available.</p>
                    )
                  )}
                </div>

                {!invoiceHistoryData?.assignmentInvoiceHistory || invoiceHistoryData?.assignmentInvoiceHistory?.[0]?.agencyPayInvoiceId ?
                  <></>
                : (
                  <div className="pb2">
                    <h4 className="f5 fw6">Change gross pay</h4>

                    <div className="w-100">
                      <label htmlFor="newWage" className="f6 fw5 db mb2 mt3">
                        <span className='db pb1'>{wageEditedAssignment?.job?.name}</span>
                        <small className='db gray'>({wageEditedAssignment?.job.publicId})</small>
                      </label>

                      <div className="w-20 relative mv3 dt dib wage-change-input">
                        <div className="b--black-10 bb bt bl pa1 pl2 pr2 br2 br--left dtc dib black-50 w1 sign">$</div>
                        <Field
                          name="newWage"
                          className="dtc dib bt bb outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2 br--right"
                          type="text"
                          placeholder="e.g., 5000"
                          value={wageValue}
                          onChange={handleInputChange}
                        />

                        <div className="update-wage-button">
                          <UpdateWageButton submitting={isSubmitting} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
  function renderWorkHistoryContent(jobsArray){
    return (
      <>
        {renderWageEditModal()}
        {renderWorkHistoryTable(jobsArray)}
      </>
    );
  }
  
  return (
    <div className="fn fl-ns w-75-ns">
      <div>
        <h3 className="dark-gray bb b--black-10 mt0 pb3">
          Applications
        </h3>
        <div className="gray f6 fw2 lh-copy">
        { insuranceApplications.length > 0 ? 
          renderApplicationTable(insuranceApplications) : 
          "No pending insurance applications."
        }
        </div>

      </div>
      {policies.length > 0 && 
        <div className="mt5">
          <h3 className="dark-gray bb b--black-10 mt0 pb3">
            Active Coverage
          </h3>
          <div className="gray f6 fw2 lh-copy">
            {renderActiveCoverageTable(policies)}
          </div>
        </div>
      }
      {filteredCertificates.length > 0 &&
        <div className="mt5">
          <h3 className="dark-gray bb b--black-10 mt0 pb3">
            Reviewed Certificates
          </h3>
          <div className="gray f6 fw2 lh-copy">
            {renderReviewedCertificateTable(filteredCertificates)}
          </div>
        </div>
      }
      <div className="mt5">
        <div className="db dt bb b--black-10 w-100">
          <div className="dtc w-70">
            <h3 className="dark-gray db v-btm">
              Work History
            </h3>
          </div>
          {policies.length > 0 && 
            <div className="dtc relative">
              <button 
                type="button" 
                onClick={props.openModal}
                className="db v-mid outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title bg-animate hover-bg-light-gray border-box absolute bottom-1 right-0">
                + Add Job Assignment
              </button>
            </div>
          }
        </div>
        
        <div className="gray f6 fw2 lh-copy">
          { jobsArray.length > 0 ? 
          renderWorkHistoryContent(jobsArray) :
          <span className="db mt3">No jobs reported.</span>
          }
        </div>
      </div>
    </div>
  )
}

const renderSuccess = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-green green mv3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

const renderError = (message) => (
  <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red mv3">
    <span className="lh-title ml3">{message}</span>
  </div>
)

function hasActivePolicy(quotes){
  return quotes.edges?.some(quote => quote?.node?.policy?.edges[0]?.node?.isActive)
}

function hasAnApplicationInProgress(quotes){
  return quotes.edges?.some(quote => quote?.node?.insuranceApplication?.applicationStarted)
}

function initValues(data){
  let allQuotes = data?.quotes?.edges
  let allAssignments = data?.assignments?.edges

  return {
    contractorId: data?.publicId,
    firstName: data?.firstName,
    middleName: data?.middleName,
    lastName: data?.lastName,
    email: data?.email,
    line1: data?.address?.line1 || "",
    line2: data?.address?.line2 || "",
    line3: data?.address?.line3 || "",
    locality: data?.address?.locality || "",
    region: data?.address?.region,
    postalcode: data?.address?.postalcode || "",
    poContactName: data?.poContactName || "",
    poContactEmail: data?.poContactEmail || "",
    withholdPremium: data?.withholdPremium || "",
    jobs: buildJobHistory(allQuotes, allAssignments),
  }
}

function Contractor(props){
  const { isTestView, isAdmin, token } = props
  
  const [isActiveEdit, setIsActiveEdit] = useState(false)
  const [newAssignments, setNewAssignments] = useState()
  
  // Note: newApplicationUrls vs. newApplicationRecords 
  //
  // newApplicationUrls is used for expireRenew mutation results
  // newApplicationRecords is used for createQuote mutation results
  //
  const [newApplicationRecords, setNewApplicationRecords] = useState()
  const [newApplicationUrls, setNewApplicationUrls] = useState([])
  
  const [successMessage, setSuccessMessage] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [cancelPolicyModalValues, setCancelPolicyModalValues] = useState({
    cancelPolicyModalIsOpen: false,
    policy: ''
  })
  const [cancelAssignmentModalValues, setCancelAssignmentModalValues] = useState({
    cancelAssignmentModalIsOpen: false,
    assignment: ''
  })
  const [confirmedCancelledPolicies, setConfirmedCancelledPolicies] = useState([])

  const location = useLocation();
  const navigate = useNavigate();

  const { contractorId } = useParams();

  const [data, setData] = useState(null);
  
  const { loading, error, data: queryData } = useQuery(CONTRACTOR_BY_ID_QUERY, {
    variables: { id: contractorId }, // replace with your actual contractor ID
    fetchPolicy: 'cache-and-network', // or 'cache-first' for initial load from cache then network
    skip: location.state !== null, // skip the query if location.state is not undefined
  });

  useEffect(() => {
    if (location.state) {
      setData(location.state);    
      // Navigate to the same path without state to clear it
      navigate(location.pathname, { replace: true });
    }
    else if (!loading && !error) {
      const resolvedContractor = resolveRedacted(queryData?.contractor, 
        JSON.parse(queryData?.contractor?.unredactedDict));
      
      setData(resolvedContractor);
    }
  }, [navigate, location, loading, error, queryData]);

  const [updateContractorDetails, {loading: saving, error: savingError, reset}] = useMutation(UPDATE_CONTRACTOR_DETAILS, {
    onCompleted() {
      reset()
      setIsActiveEdit(false)
      navigate(location.pathname, {state: null});
    },
    onError(error){
      setIsActiveEdit(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CONTRACTOR_BY_ID_QUERY,
        variables: { id: contractorId }, // Specify the variables for the query
      }
    ],
  })

  function saveContractorDetails(values){
    const customMetadata = {
      purchaseOrderContactName: values.poContactName,
      purchaseOrderContactEmail: values.poContactEmail,
      purchaseOrderId: values.poId,
    }

    const contractor = {
      id: values.contractorId,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      line1: values.line1,
      line2: values.line2,
      line3: values.line3,
      locality: values.locality,
      region: values.region,
      postalcode: values.postalcode,
      customMetadata: customMetadata
    }

    const job = {
      id: values.jobs[0].node?.job?.publicId,
      name: values.jobs[0].node?.job?.name,
      wage: parseInt(values.jobs[0].node?.job?.wage)
    }
    
    const entity = {
      id: values.jobs[0].node?.job?.entity?.publicId,
      name: values.jobs[0].node?.job?.entity?.name,
    }

    const quote = {
      id: values.jobs[0].node?.publicId,
      effectiveDate: values.jobs[0].node?.effectiveDate,
      endDate: values.jobs[0].node?.endDate,
      workState: values.jobs[0].node?.workState,
    }
    
    updateContractorDetails({
      variables: {
        contractor: contractor,
        job: job,
        entity: entity,
        quote: quote,
      }
    })
  }
  let page;

  const renderLoading = () => (
    <>
      <MainWrapper isTestView={isTestView}>
        <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
      </MainWrapper>
    </>
  )

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate(-1);
  };


  const renderPage = () => (
    <>
      <MainWrapper isTestView={isTestView}>
        {successMessage && renderSuccess(successMessage)}
        {errorMessage && renderError(errorMessage)}

        <div className="flex-item content-center absolute top-1 left-1">
          <div className="back-arrow" onClick={handleBackClick}>
            <span className="f6 hover-bg-light-gray pointer pv2 ph3 br4">← Back</span>
          </div>
        </div>

        <article className="dib w-100 mt3">
          <Formik
            initialValues={initValues(data)}
            validationSchema={createValidationSchema(initValues(data))}
            onSubmit={(values, {setSubmitting}) => {
              saveContractorDetails(values)
              setSubmitting(true)
            }}
            > 
            {(formikProps) => {
              return (
                <Form onSubmit={formikProps.handleSubmit}>
                { (isAdmin && !hasAnApplicationInProgress(data?.quotes) && !hasActivePolicy(data?.quotes)) &&
                    <EditButton
                      isLoading={saving}
                      saveContractorDetails={saveContractorDetails}
                      setIsActiveEdit={setIsActiveEdit} 
                      isActiveEdit={isActiveEdit}
                      values={formikProps.values}
                      {...formikProps}
                    />
                  }
                  <ContractorSideNav
                    {...formikProps}
                    isActiveEdit={isActiveEdit} 
                    data={data}
                  />
                  <ContractorMain 
                    {...props}
                    {...formikProps}
                    isAdmin={isAdmin}
                    newAssignments={newAssignments}
                    newApplicationRecords={newApplicationRecords}
                    newApplicationUrls={newApplicationUrls}
                    setNewApplicationUrls={setNewApplicationUrls}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                    isActiveEdit={isActiveEdit}
                    data={data} 
                    openModal={() => setModalIsOpen(true)}
                    setCancelPolicyModalValues={setCancelPolicyModalValues}
                    confirmedCancelledPolicies={confirmedCancelledPolicies}
                    setCancelAssignmentModalValues={setCancelAssignmentModalValues}
                  />
                </Form>
            )
          }}
          </Formik>
        </article>
        <CancelPolicyModal 
          openModal={() => setCancelPolicyModalValues({...cancelPolicyModalValues, cancelPolicyModalIsOpen: true})}
          closeModal={() => setCancelPolicyModalValues({...cancelPolicyModalValues, cancelPolicyModalIsOpen: false})}
          modalIsOpen={cancelPolicyModalValues.cancelPolicyModalIsOpen}
          policy={cancelPolicyModalValues.policy}
          confirmedCancelledPolicies={confirmedCancelledPolicies}
          setConfirmedCancelledPolicies={setConfirmedCancelledPolicies}
          setSuccessMessage={setSuccessMessage}
          token={token}
        />
         <CancelAssignmentModal
          openModal={() => setCancelAssignmentModalValues({...cancelAssignmentModalValues, cancelAssignmentModalIsOpen: true})}
          closeModal={() => setCancelAssignmentModalValues({...cancelAssignmentModalValues, cancelAssignmentModalIsOpen: false})}
          modalIsOpen={cancelAssignmentModalValues.cancelAssignmentModalIsOpen}
          assignment={cancelAssignmentModalValues.assignment}
          setSuccessMessage={setSuccessMessage}
          token={props.token}
        />
        <JobAssignmentModal 
          {...props} 
          setNewAssignments={setNewAssignments}
          setNewApplicationRecords={setNewApplicationRecords}
          setSuccessMessage={setSuccessMessage}
          errorMessage={modalErrorMessage}
          setErrorMessage={setModalErrorMessage}
          data={data} 
          openModal={() => setModalIsOpen(true)}
          closeModal={() => {
            setModalIsOpen(false)
            setModalErrorMessage(false)
          }}
          modalIsOpen={modalIsOpen}
          contentLabel={"Create Job Assignment"} 
        />
      </MainWrapper>
    </>
  )
  
  page = data ? renderPage() : renderLoading()

  return (
    <>
      {page}
    </>
  )
}
export default Contractor