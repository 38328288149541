import React, { useEffect, useState, useRef } from "react";
import { FieldArray } from "formik";

import { startAndEnd } from "../../../utils/truncate";

const Pill = ({ value, onRemove, fieldArrayRemove, disabled }) => (
  <div className="mt2">
    <button
      type="button"
      className={`br4 ba f7 pa2 ${
        disabled
          ? "b--black-10 bg-black-5"
          : "bg-washed-blue b--blue blue pointer"
      }`}
      onClick={() => {
        if (!disabled) {
          onRemove(value, fieldArrayRemove);
        }
      }}
    >
      <div className="flex items-center space-between">
        <span className="tl">{startAndEnd(value, 42)}</span>
        {!disabled && <img className="dib pl1 w1" src="/x.svg" alt="Close" />}
      </div>
    </button>
  </div>
);

const PillInput = ({ allowedValues, selectedJobCategories, disabled }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState(selectedJobCategories);
  const [suggestions, setSuggestions] = useState([]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const updateSuggestions = (value) => {
    setInputValue(value);
    if (value === "") {
      setSuggestions(
        allowedValues.filter((val) => !selectedValues.includes(val))
      );
    } else {
      const filteredSuggestions = allowedValues
        .filter((val) => val.toLowerCase().includes(value.toLowerCase()))
        .filter((val) => !selectedValues.includes(val));
      setSuggestions(filteredSuggestions);
    }
  };

  const handleAddValue = (value) => {
    if (allowedValues.includes(value) && !selectedValues.includes(value)) {
      setSelectedValues([...selectedValues, value]);
      setInputValue("");
      setSuggestions([]);
    }
  };

  const handleRemoveValue = (value, remove) => {
    setSelectedValues(selectedValues.filter((val) => val !== value));
    remove(value);
  };

  const handleSuggestionClick = (suggestion, push) => {
    handleAddValue(suggestion);
    push(suggestion);
  };

  return (
    <div className="w-30 relative" ref={wrapperRef}>
      <FieldArray
        name="jobCategories"
        render={({ push, remove }) => (
          <>
            <input
              value={inputValue}
              onChange={(e) => updateSuggestions(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleAddValue(inputValue)}
              onFocus={() => updateSuggestions("")}
              placeholder="Type and press enter"
              className="outline-0 pa2 f6 fw3 ba b--black-10 br2 border-box w-100"
              disabled={disabled}
            />
            {suggestions.length > 0 && (
              <ul className="ba b--black-10 list ma0 w-100 pa0 absolute z-1 bg-white">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="pointer pa2 hover-bg-light-gray list"
                    onClick={() => handleSuggestionClick(suggestion, push)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
            <div className="pills-container">
              {selectedValues.map((value, index) => (
                <Pill
                  key={index}
                  value={value}
                  onRemove={handleRemoveValue}
                  fieldArrayRemove={remove}
                  disabled={disabled}
                />
              ))}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default PillInput;
