import React, { useState, useContext } from "react";

import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { environments } from "../constants/environments";

import { Routes, Route } from "react-router-dom";

import Switch from "react-switch";

import NavigationHeader from "../components/NavigationHeader";

import Contractor from "../pages/ContractorPage/Contractor";
import CertificateReviewDetail from "../pages/CertificateReviewPage/CertificateReviewDetail";
import TrackerDetail from "../pages/TrackerPage/TrackerDetail";

import JobCode from "../pages/JobPage/JobCode";

import AdminSelectOrganization from "../pages/OrganizationPage/AdminSelectOrganization";
import CreateOrganization from "../pages/OrganizationPage/CreateOrganization";
import JoinOrganization from "../pages/OrganizationPage/JoinOrganization";
import OrganizationInvitationBanner from "../pages/OrganizationPage/OrganizationInvitationBanner";

import { PENDING_INVITATIONS_QUERY } from "../pages/OrganizationPage/queries";

import ProfilePage from "../pages/Profile";
import NotFound from "../pages/NotFound";

import OrganizationSwitcher from "../components/OrganizationSwitcher";

import { gqlErrors } from "../constants/errors";
import { roles } from "../constants/roles";

import { ORGANIZATION_BY_ID_QUERY } from "./queries";

import * as cognito from "../libs/cognito";

export const CurrentUserContext = React.createContext({});

const LeftNavigation = ({ routes, client, isAdmin, setIsTestView }) => {
  let general = [];
  let organizations = [];
  let developers = [];
  let admin = [];

  const target_env =
    localStorage.getItem("Ten99PolicyEnvironment") || environments.PRODUCTION;

  const toggleValue = target_env === environments.SANDBOX;

  const [checked, setChecked] = useState(toggleValue);
  const { user } = useContext(CurrentUserContext);

  const checkFlag = (task) => {
    const flags = user?.feature?.edges.map((o) => o?.node?.key);
    return isAdmin || flags.includes(task);
  };

  const handleChange = (checked) => {
    const environment = checked
      ? environments.SANDBOX
      : environments.PRODUCTION;

    localStorage.setItem("Ten99PolicyEnvironment", environment);
    localStorage.removeItem("Ten99PolicyOrganization");
    setChecked(checked);
    setIsTestView(checked);

    client.refetchQueries({
      include: "all",
    });
  };

  const routeParams = {
    user,
    client,
    isAdmin,
  };

  routes.map((route, index) => {
    switch (route.section) {
      case "admin":
        admin.push(
          <li key={index} className="mb2">
            {route.sidebar(routeParams)}
          </li>
        );
        break;
      case "developers":
        developers.push(
          <li key={index} className="mb2">
            {route.sidebar(routeParams)}
          </li>
        );
        break;
      case "organization":
        organizations.push(
          <li key={index} className="mb2">
            {route.sidebar(routeParams)}
          </li>
        );
        break;
      case "general":
        general.push(
          <li key={index} className="mb2">
            {route.sidebar(routeParams)}
          </li>
        );
        break;
    }

    return null;
  });

  return (
    <nav className="w-100 w-20-m w-20-l mb4 mb0-l ph3-m ph3-l">
      <div>
        <h2 className="ttu mt0 mb2 f6 fw5 silver">General</h2>
        <ul className="list pl0 mt0 mb4">{general}</ul>
      </div>
      <div>
        <h2 className="ttu mt0 mb2 f6 fw5 silver">Organization</h2>
        <ul className="list pl0 mt0 mb4">{organizations}</ul>
      </div>
      <div>
        <h2 className="ttu mt0 mb2 f6 fw5 silver">Developers</h2>
        <ul className="list pl0 mt0 mb4">{developers}</ul>
      </div>
      {isAdmin ? (
        <div>
          <h2 className="ttu mt0 mb2 f6 fw5 silver">Admin</h2>
          <ul className="list pl0 mt0 mb4">{admin}</ul>
        </div>
      ) : null}

      <div className="flex-l">
        <Switch
          checked={checked}
          onChange={handleChange}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={15}
          uncheckedIcon={false}
          checkedIcon={false}
          height={10}
          width={24}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          className="react-switch"
          id="material-switch"
        />
        <label className="f6 near-black ml2" htmlFor="testData">
          View test data
        </label>
      </div>
    </nav>
  );
};

const handleError = (error, client) => {
  switch (error) {
    case gqlErrors.REFRESH_TOKEN:
      console.log("refresh token");
      return <></>;
    case gqlErrors.UNAUTHENTICATED:
      console.log("unauthenticated");
      return <></>;
    case gqlErrors.SELECT_ORGANIZATION:
      return <AdminSelectOrganization client={client} />;
    case gqlErrors.ORGANIZATION_REQUIRED:
      return <CreateOrganization client={client} />;
    case gqlErrors.ORGANIZATION_INVITED:
      return <JoinOrganization client={client} />;
    default:
      return <div className="measure ma3"> {error.message} </div>;
  }
};

function BaseDashboardWrapper({
  token,
  routes,
  children,
  client,
  loading,
  error,
  data,
}) {
  const currentUserEmail = cognito.getCurrentUserEmail();

  const { data: inviteData, loading: inviteLoading } = useQuery(
    PENDING_INVITATIONS_QUERY,
    {
      variables: {
        email: currentUserEmail,
      },
    }
  );

  const toggleValue =
    localStorage.getItem("Ten99PolicyEnvironment") === environments.SANDBOX;
  const [isTestView, setIsTestView] = useState(toggleValue);

  const organizationId = localStorage.getItem("Ten99PolicyOrganization");

  const {
    loading: orgLoading,
    error: orgError,
    data: orgData,
  } = useQuery(ORGANIZATION_BY_ID_QUERY, {
    variables: { id: organizationId },
    notifyOnNetworkStatusChange: true,
    skip: !organizationId,
  });

  if (error) {
    const { extensions } = error?.graphQLErrors[0];
    return handleError(extensions?.status, client);
  }

  if (loading) {
    return <div></div>;
  }

  const readyToRender = organizationId
    ? data && orgData && inviteData
    : data && inviteData;

  if (readyToRender) {
    const user = data.currentUser;
    const organizationList = user?.organizations?.edges;
    const defaultOrganization =
      user?.organizations?.edges[0]?.node?.organization;

    const organization = organizationId
      ? orgData.organization
      : defaultOrganization;

    const isAdmin = user?.roles?.edges[0]?.node?.name === roles.ADMIN;
    const orgId = organization?.publicId;

    const routeParams = {
      user,
      token,
      client,
      isTestView,
      organization,
      isAdmin,
      orgId,
    };

    return (
      <CurrentUserContext.Provider value={{ user, isAdmin }}>
        <main className="roboto">
          <NavigationHeader client={client} email={user?.email} />
          <OrganizationSwitcher
            organization={organization}
            organizationList={organizationList}
            isAdmin={isAdmin}
            client={client}
          />
          <div className="mw9 center pv4 ph3 mh4" id="dashboard">
            <section className="flex-m flex-l nl3-m nr3-m nl3-l nr3-l">
              <LeftNavigation
                routes={routes}
                client={client}
                isAdmin={isAdmin}
                setIsTestView={setIsTestView}
              />
              <article className="w-100 w-80-m w-80-l ph3-m ph3-l">
                {inviteData?.pendingInvitations.length > 0 && (
                  <OrganizationInvitationBanner
                    pendingInvitations={inviteData?.pendingInvitations}
                  />
                )}

                {children}
                <Routes>
                  <Route
                    path="/job-codes/:jobId"
                    element={<JobCode isTestView={routeParams.isTestView} />}
                  />
                  <Route
                    path="/contractors/:contractorId"
                    element={
                      <Contractor
                        token={routeParams.token}
                        isTestView={routeParams.isTestView}
                        isAdmin={routeParams.isAdmin}
                      />
                    }
                  />
                  <Route
                    path="/certificates/:certificateReviewId"
                    element={
                      <CertificateReviewDetail
                        isTestView={routeParams.isTestView}
                        isAdmin={routeParams.isAdmin}
                      />
                    }
                  />
                  <Route
                    path="/trackers/:trackerId"
                    element={
                      <TrackerDetail
                        token={routeParams.token}
                        isTestView={routeParams.isTestView}
                        isAdmin={routeParams.isAdmin}
                        client={client}
                      />
                    }
                  />
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.main(routeParams)}
                    />
                  ))}
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </article>
            </section>
          </div>
        </main>
      </CurrentUserContext.Provider>
    );
  }
  return <div></div>;
}

function DashboardWrapper(props) {
  return <BaseDashboardWrapper {...props} />;
}

export default DashboardWrapper;
